import {
  clearOtherUserSessions,
  disableUserTwoFactor,
  enableUserTwoFactor,
  regenerateTwoFactorCodes,
  updateUserPassword
} from '@/api/profile';

import {
  CONFIRMATION_REQUIRED_MODAL,
  PASSWORD_CONFIRMATION_REQUIRED_MODAL,
  UPDATE_USER_PASSWORD_MODAL
} from '@/constants/modals';

import { SESSIONS_API } from '@/constants/api';

import type { PasswordConfirmationParams, UpdateUserPasswordParams } from '@/api/profile/types';

import type { UseModal } from '@/types/modals';
import type { PromiseResolve} from '@/types/helpers';
import { LOGIN_TYPES_NAMES, type LoginTypes } from "@/constants/user";

export function openUpdateUserPasswordModal(openModal: UseModal['openModal']) {
  async function updatePasswordHandler(
    resolve: PromiseResolve,
    updateUserPasswordParams: UpdateUserPasswordParams
  ) {
    const data = await updateUserPassword(updateUserPasswordParams);
    resolve(data);
  }

  openModal<typeof UPDATE_USER_PASSWORD_MODAL>(UPDATE_USER_PASSWORD_MODAL, {
    confirmAction: updatePasswordHandler,
    useStatusConfirmation: true,
    successMessage: 'profile.password_changed_successfully'
  });
}

export function openClearSessionModal(
  openModal: UseModal['openModal'],
  loginType: LoginTypes
) {
  async function clearSessionHandler(
    resolve: PromiseResolve,
    clearSessionsParams: PasswordConfirmationParams
  ) {
    const data = await clearOtherUserSessions(clearSessionsParams);
    resolve(data);
  }

  async function clearSessionHandlerSso(
    resolve: PromiseResolve
  ) {
    const data = await clearOtherUserSessions();
    resolve(data);
  }

  if (loginType === LOGIN_TYPES_NAMES.TYPE_SSO) {
    openModal<typeof CONFIRMATION_REQUIRED_MODAL>(
      CONFIRMATION_REQUIRED_MODAL,
      {
        confirmAction: clearSessionHandlerSso,
        useStatusConfirmation: true,
        invalidateQueryKey: SESSIONS_API,
        successMessage: 'userProfile.clear_other_sessions_success',
        title: 'userProfile.clear_other_sessions'
      }
    );

    return;
  }

  openModal<typeof PASSWORD_CONFIRMATION_REQUIRED_MODAL>(
    PASSWORD_CONFIRMATION_REQUIRED_MODAL,
    {
      confirmAction: clearSessionHandler,
      useStatusConfirmation: true,
      invalidateQueryKey: SESSIONS_API,
      successMessage: 'userProfile.clear_other_sessions_success',
      title: 'userProfile.clear_other_sessions'
    }
  );
}

export function openEnableUserTwoFactorConfirmationModal(
  openModal: UseModal['openModal'],
  loginType: LoginTypes
) {
  async function onEnableUserTwoFactorHandler(
    resolve: PromiseResolve,
    params: PasswordConfirmationParams
  ) {
    const data = await enableUserTwoFactor(params);

    resolve(data);
  }

  async function onEnableUserTwoFactorHandlerSso(
    resolve: PromiseResolve
  ) {
    const data = await enableUserTwoFactor();

    resolve(data);
  }

  if (loginType === LOGIN_TYPES_NAMES.TYPE_SSO) {
    openModal<typeof CONFIRMATION_REQUIRED_MODAL>(
      CONFIRMATION_REQUIRED_MODAL,
      {
        confirmAction: onEnableUserTwoFactorHandlerSso,
        title: 'userProfile.enable_two_factor_authentication',
        useStatusConfirmation: true,
        successMessage: 'profile.two_factor_authentication_enabled_successfully'
      }
    );

    return;
  }

  openModal<typeof PASSWORD_CONFIRMATION_REQUIRED_MODAL>(
    PASSWORD_CONFIRMATION_REQUIRED_MODAL,
    {
      confirmAction: onEnableUserTwoFactorHandler,
      title: 'userProfile.enable_two_factor_authentication',
      useStatusConfirmation: true,
      successMessage: 'profile.two_factor_authentication_enabled_successfully'
    }
  );
}

export function openDisableUserTwoFactorConfirmationModal(
  openModal: UseModal['openModal'],
  loginType: LoginTypes
) {
  async function onDisableUserTwoFactorHandler(
    resolve: PromiseResolve,
    params: PasswordConfirmationParams
  ) {
    const data = await disableUserTwoFactor(params);

    resolve(data);
  }

  async function onDisableUserTwoFactorHandlerSso(
    resolve: PromiseResolve,
  ) {
    const data = await disableUserTwoFactor();

    resolve(data);
  }

  if (loginType === LOGIN_TYPES_NAMES.TYPE_SSO) {
    openModal<typeof CONFIRMATION_REQUIRED_MODAL>(
      CONFIRMATION_REQUIRED_MODAL,
      {
        confirmAction: onDisableUserTwoFactorHandlerSso,
        title: 'organization.disable_two_factor',
        useStatusConfirmation: true,
        successMessage: 'profile.two_factor_authentication_disabled_successfully'
      }
    );

    return;
  }

  openModal<typeof PASSWORD_CONFIRMATION_REQUIRED_MODAL>(
    PASSWORD_CONFIRMATION_REQUIRED_MODAL,
    {
      confirmAction: onDisableUserTwoFactorHandler,
      title: 'organization.disable_two_factor',
      useStatusConfirmation: true,
      successMessage: 'profile.two_factor_authentication_disabled_successfully'
    }
  );
}

export function openRegenerateTwoFactorRecoveryCodesConfirmationModal(
  openModal: UseModal['openModal'],
  loginType: LoginTypes
) {
  async function onRegenerateTwoFactorRecoveryCodesHandler(
    resolve: PromiseResolve,
    params?: PasswordConfirmationParams
  ) {
    const data = await regenerateTwoFactorCodes(params);

    resolve(data);
  }

  async function onRegenerateTwoFactorRecoveryCodesHandlerSso(
    resolve: PromiseResolve,
  ) {
    const data = await regenerateTwoFactorCodes();

    resolve(data);
  }

  if (loginType === LOGIN_TYPES_NAMES.TYPE_SSO) {
    openModal<typeof CONFIRMATION_REQUIRED_MODAL>(
      CONFIRMATION_REQUIRED_MODAL,
      {
        confirmAction: onRegenerateTwoFactorRecoveryCodesHandlerSso,
        useStatusConfirmation: true,
        title: 'userProfile.Regenerate Recovery Codes',
        successMessage:
          'profile.two_factor_authentication_recovery_codes_regenerated_successfully'
      }
    );

    return;
  }

  openModal<typeof PASSWORD_CONFIRMATION_REQUIRED_MODAL>(
    PASSWORD_CONFIRMATION_REQUIRED_MODAL,
    {
      confirmAction: onRegenerateTwoFactorRecoveryCodesHandler,
      useStatusConfirmation: true,
      title: 'userProfile.Regenerate Recovery Codes',
      successMessage:
        'profile.two_factor_authentication_recovery_codes_regenerated_successfully'
    }
  );
}
